import React from 'react';
import styled from 'styled-components';
import theme from '../styles/theme';
import { trackOutboundLinkClick } from './analytics';
import '../styles/Footer.css'; 

const Footer = () => {
  return (
    <FooterContainer>
      <FooterSections>
        <LogoSocials>
          <a href="/" className="logo">
            <img src="./images/logo-icon.png" alt="Logo" className="logo-image" />
          </a>
          <p>Elevating Code with AI-Powered Insights</p>
          <SocialLinks>
            <a href="https://www.linkedin.com/in/bhuvnesh-arya/" target="_blank" className="social-link linkedin" rel="noopener noreferrer" onClick={() => trackOutboundLinkClick('https://www.linkedin.com/in/bhuvnesh-arya/')}>
              <img src="./images/linkedin.png" alt="LinkedIn" />
            </a>
            <a href="https://www.youtube.com/@bhuvnesharya10/" target="_blank" className="social-link youtube" rel="noopener noreferrer" onClick={() => trackOutboundLinkClick('https://www.youtube.com/@bhuvnesharya10/')}>
                <img src="./images/youtube.png" alt="Youtube" />
            </a>
            <a href="https://x.com/bhuvnesharya_in/" target="_blank" className="social-link twitter" rel="noopener noreferrer" onClick={() => trackOutboundLinkClick('https://x.com/bhuvnesharya_in/')}>
              <img src="./images/twitter-x.png" alt="Twitter" />
            </a> 
            <a href="https://github.com/bhuvnesharya/" target="_blank" className="social-link github" rel="noopener noreferrer" onClick={() => trackOutboundLinkClick('https://github.com/bhuvnesharya/')}>
              <img src="./images/github.png" alt="GitHub" />
            </a>
          </SocialLinks>
        </LogoSocials>
        <WritingsLearnings>
          <p>Writings and Learnings</p>
          <a href="/lessons">Lessons</a>
          <a href="/blogs">Blogs</a>
        </WritingsLearnings>
        <LegalContact>
          <p>Legal and Contact</p>
          <a href="/about-me">About Me</a>
          <a href="/contact-me">Contact Me</a>
          <a href="/privacy-policy.html">Privacy Policy</a>
        </LegalContact>
      </FooterSections>
      <hr className="separator-line" />
      <FooterText>&copy; 2024 CodeAndBeyond. All rights reserved.</FooterText>
    </FooterContainer> 
  );
};

const FooterContainer = styled.footer`
  background-color: ${theme.colors.primaryBackground};
  color: ${theme.colors.secondaryText};
  text-align: center;
  padding: 20px;
`;

const FooterText = styled.p`
  margin: 0;
`;

const FooterSections = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;

  div {
    margin: 0 20px;
  }
`;

const LogoSocials = styled.div`
  p {
    margin-bottom: 20px;
    margin-right: 35px;
  }
  
  a {
    display: block;
    color: ${theme.colors.secondaryText};
    text-decoration: none;
    margin-bottom: 5px;
    text-align: left;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const WritingsLearnings = styled.div`
  p {
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  a {
    display: block;
    color: ${theme.colors.secondaryText};
    text-decoration: none;
    margin-bottom: 5px;
    text-align: left;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const LegalContact = styled.div`
  p {
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  a {
    display: block;
    color: ${theme.colors.secondaryText};
    text-decoration: none;
    margin-bottom: 5px;
    text-align: left;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const SocialLinks = styled.div`
  display: table-cell;
  flex-direction: row;
  align-items: flex-start; /* Align links to the start (left) */
  
  gap: 10px; /* Adjust gap between icons */
  
  background-color:  ${theme.colors.social}; /* Background color of the box */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  
  margin: 0 auto; /* Center the box horizontally */
  

  a {
    margin: 0 5px;
    display: inline-block;
    
    img {
      width: 25px; /* Size of icons */
      height: 25px;
      transition: transform 0.3s ease; /* Smooth animation on hover */
      margin-right: 8px;
      margin-left: 8px;
      margin-top: 5px;
      margin-bottom: 3px;
    }

    &:hover {
      opacity: 0.8;
    }
  }
`;

export default Footer;
