import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Added Link for navigation
import { supabase } from '../authentication/supabaseClient';
import '../styles/SignInPage.css';

const SignInPage = () => {
  const [staySignedIn, setStaySignedIn] = useState(false); // State for "Stay signed in"
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSession = async () => {
      const { data: { session }, error } = await supabase.auth.getSession();
      if (error) {
        console.error("Error fetching session: ", error.message);
      } else if (session) {
        navigate("/blogs"); // Redirect to /blogs after sign-in
      }
    };

    fetchSession();
  }, [navigate]);

  return (
    <div className="sign-in-container">
      <div className="sign-in-card">
        <div className="sign-in-info">
          <h1>Welcome to CodeAndBeyond</h1>
          <p>Join our platform to gain AI-powered insights into your code, access articles, and enhance your skills with our system design, engineering practices, and architecture lessons.</p>
          <ul>
            <li>Learn from experts in software engineering.</li>
            <li>Get personalized code reviews and feedback.</li>
            <li>Advance your career with cutting-edge insights.</li>
          </ul>
        </div>

        {/* Create Your Account Button */}
        <div className="create-account-link">
          <Link to="/signup">
            <button className="create-account-button">Create your account</button>
          </Link>
        </div>
      </div>

      <div className="sign-in-form">
        <h2>Sign In</h2>
        <form>
          <input type="email" placeholder="Email Address" required />
          <input type="password" placeholder="Password" required />

          {/* Stay Signed In & Forgot Password */}
          <div className="form-footer">
            <div className="stay-signed-in">
              <input
                type="checkbox"
                checked={staySignedIn}
                onChange={() => setStaySignedIn(!staySignedIn)}
              />
              <label>Stay signed in</label>
            </div>
            <a href="/reset-password" className="forgot-password-link">Forgot Password?</a>
          </div>

          <button type="submit" className="email-sign-in-button">Sign in with Email</button>
        </form>

      </div>
    </div>
  );
};

export default SignInPage;
