// analytics.js

export const trackOutboundLinkClick = (url) => {
    if (window.gtag) {
      window.gtag('event', 'click', {
        'event_category': 'outbound',
        'event_label': url,
        'transport_type': 'beacon',
      });
    }
  };
  