import React, { useEffect } from 'react';
import Introduction from '../components/Introduction'; // Adjust the path as needed
import '../styles/HomePage.css'; // Make sure to create a corresponding CSS file
import ReactGA from 'react-ga4';
import Lessons from '../components/Lessons';
import Blogs from '../components/Blogs';

const HomePage = () => {
    useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }, []);
    return (
      <div className="homepage">
        <div className="hero-section">
          <div className="hero-content">
            <h1 className="tagline">Empower Your Coding Journey</h1>
            <p className="subtagline">Get insightful code reviews, articles, and more to enhance your skills.</p>
          </div>
        </div>
        <Introduction />
        <Lessons />
        <Blogs />
      </div>
    );
  }

export default HomePage;
