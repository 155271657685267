import React from 'react';
import '../styles/Introduction.css'; // Create a CSS file for styling
import { Link } from 'react-router-dom';

const Blogs = () => {
  return (
    <div className="intro-container">
      <div className="intro-content">
      <div className="intro-image">
            <a href="/blogs"><img src="./images/blogs.png" alt="Blogs" href="/blogs" /></a>
        </div>
      <div className="home-container">
      <p>The <Link style={{color: '#0069d2'}} to="/blogs"><b>Blogs</b></Link> page contains a collection of thoughtfully crafted articles on software engineering, system design, and cutting-edge technologies. Each blog post dives deep into industry best practices, offering practical insights and real-world examples to help you grow as a software engineer. Stay informed with the latest trends, techniques, and perspectives that can elevate your engineering skills to the next level. </p> 
    </div>
      </div>
      
    </div>
  );
}

export default Blogs;