import React, { useEffect } from 'react';
import BlogData from '../components/BlogData';
import '../styles/BlogsPage.css';
import { trackOutboundLinkClick } from '../components/analytics';
import ReactGA from 'react-ga4';

const BlogsPage = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);
  return (
    <div className="blogs-page-container">
      <h1>All Articles</h1>
      <div className="blogs-list-container">
        {BlogData.map((article, index) => (
          <div key={index} className="blog-card">
            <img src={article.thumbnail} alt={article.title} className="blog-thumbnail" />
            <div className="blog-content">
              <h3>{article.title}</h3>
              <p>{article.description}</p>
              <a href={article.link} target="_blank" rel="noopener noreferrer" onClick={() => trackOutboundLinkClick('{article.link}')}>
                Read More
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogsPage;
