// src/styles/theme.js


const theme = {
    colors: {
      primaryBackground: '#16361b',  // Dark
      accent: '#c9c232',             // Primary
      text: '#c9c232',                  // Light
      secondaryText: '#c9c232',      // Gray
      secondaryBackground: '#36344d', // Gray Dark
      contentText: '#0069d2',          // Light Blue
      danger: '#fc5185',             // Danger
      warning: '#ffcd35',            // Warning
      success: '#00b090',            // Success
      azure: '#357df9',              // Azure
      social: '#c9c232',             // Social Grey  
    },
    fonts: {
      primary: "'Cabin', sans-serif",   // Primary Font
      secondary: "'Cabin', sans-serif"  // Secondary Font
    },
    typography: {
      logo: {
        fontFamily: 'var(--font-primary)', /* Keeps the same font family */
        fontWeight: '500', /* You can adjust the weight to make it bolder or lighter */
        fontSize: '2.5rem', /* Adjust the size as needed */
        textShadow: '6px 6px 8px', /* Adds a shadow around the text */
        color: 'var(--colors-text)', /* The color of your logo text */
      },   
      h1: {
        fontSize: '50px',
        fontFamily: 'var(--font-primary)',
        fontWeight: '500',
        lineHeight: '1.2',
        mobileFontSize: '40px',
        color: 'var(--colors-contentText)'
      },
      h2: {
        fontSize: '45px',
        fontFamily: 'var(--font-primary)',
        fontWeight: '500',
        lineHeight: '1.2',
        mobileFontSize: '36px',
      },
      h3: {
        fontSize: '35px',
        fontFamily: 'var(--font-primary)',
        fontWeight: '500',
        lineHeight: '1.2',
        mobileFontSize: '32px',
      },
      h4: {
        fontSize: '30px',
        fontFamily: 'var(--font-primary)',
        fontWeight: '500',
        lineHeight: '1.2',
        mobileFontSize: '28px',
      },
      h5: {
        fontSize: '25px',
        fontFamily: 'var(--font-primary)',
        fontWeight: '500',
        lineHeight: '1.2',
        mobileFontSize: '24px',
      },
      h6: {
        fontSize: '20px',
        fontFamily: 'var(--font-primary)',
        fontWeight: '500',
        lineHeight: '1.2',
        mobileFontSize: '20px',
      },
      body: {
        fontSize: '16px',
        fontFamily: 'var(--font-secondary)',
        fontWeight: '400',
        lineHeight: '1.45',
      },
      bodyLarge: {
        fontSize: '18px',
        fontFamily: 'var(--font-secondary)',
        fontWeight: '400',
        lineHeight: '1.45',
      },
      bodySmall: {
        fontSize: '14px',
        fontFamily: 'var(--font-secondary)',
        fontWeight: '400',
        lineHeight: '1.45',
      }
    },
    button: {
      primary: {
        fontSize: '16px',
        paddingX: '32px',
        paddingY: '16px',
        fontFamily: 'var(--font-secondary)',
        fontWeight: '500',
        borderRadius: '0px',
        transition: '0.2s ease',
      },
      secondary: {
        fontSize: '16px',
        paddingX: '32px',
        paddingY: '16px',
        fontFamily: 'var(--font-secondary)',
        fontWeight: '500',
        borderRadius: '0px',
        transition: '0.2s ease',
      }
    }
  };
  
  export default theme;
  