import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import BlogsPage  from './pages/BlogsPage';
import AboutMe from './components/AboutMe'; // Adjust the path as necessary
import ContactMePage from './pages/ContactMePage';
import SignInPage from './pages/SignInPage';
import SignUpPage from './pages/SignUpPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import LessonsPage from './pages/LessonsPage';
import Lesson1 from './lessons/Lesson1';
import Lesson2 from './lessons/Lesson2';
// Import other components here...

const App = () => (
  <Router>
    <Header />
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/blogs" element={<BlogsPage />} />
      <Route path="/about-me" element={<AboutMe />} />
      <Route path="/contact-me" element={<ContactMePage />} />
      <Route path="/sign-in" element={<SignInPage />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="/lessons" element={<LessonsPage />} />
      <Route path="/lessons/lesson1" element={<Lesson1 />} />
      <Route path="/lessons/lesson2" element={<Lesson2 />} />
        {/* Add more lesson routes as needed */}
      {/* Define routes for other components here */}
    </Routes>
    <Footer />
  </Router>
);

export default App;
