import React from 'react';

const Lesson2 = () => {
  return (
    <div className="lesson-content">
      <h2>Lesson 1: Introduction to XYZ</h2>
      <p>Your notes for Lesson 2 go here...</p>
    </div>
  );
};

export default Lesson2;
