// src/components/Header.js

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import theme from '../styles/theme';
import '../styles/Header.css'; // Make sure to create a corresponding CSS file
import { supabase } from '../authentication/supabaseClient'; // Import your Supabase client

const Header = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setUser(session?.user || null);
    };

    fetchSession();

    const { data: authListener } = supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user || null);
    });

    return () => {
      authListener?.subscription?.unsubscribe();
    };
  }, []);

  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (!error) {
      navigate("/"); // Redirect to the home page after sign out
    } else {
      console.error("Error signing out: ", error.message);
    }
  };

  return (
    <HeaderContainer>
      <a href="/" className="logo">
        <img src="./images/logo.png" alt="Logo" className="logo-image" />
      </a>
      <div className="nav-container">
        <Nav>
          <NavItem href="/">Home</NavItem>
          <NavItem href="/lessons">Lessons</NavItem>
          <NavItem href="/blogs">Blogs</NavItem>
          <NavItem href="/about-me">About Me</NavItem>
          {user ? (
            <NavItem href="/" onClick={handleSignOut}>
              Sign Out
            </NavItem>
          ) : (
            <NavItem href="/sign-in">Sign In</NavItem>
          )}
        </Nav>
      </div>
    </HeaderContainer>
  );
};



const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: ${theme.colors.primaryBackground};
  height: 60px; /* Adjust this value to set the desired height */
  padding-top: 10px; /* Adjust top padding */
  padding-bottom: 10px; /* Adjust bottom padding */
  margin: 0 auto; /* Center the container */
 
`;


const Nav = styled.nav`
  display: flex;
`;

const NavItem = styled.a`
  color: ${theme.colors.text};
  margin-left: 20px;
  font-family: ${theme.fonts.secondary};
  font-size: ${theme.typography.body.fontSize};
  text-transform: uppercase;
  font-weight: ${theme.typography.body.fontWeight};
  line-height: ${theme.typography.body.lineHeight};

  &:hover {
    color: ${theme.colors.accent};
  }
`;

export default Header;
