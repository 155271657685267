import React from 'react';
import '../styles/ContactMePage.css'; // Add styling as necessary

const ContactMePage = () => {
  return (
    <div className="contact-me-container">
        <div className="contact-me-card">
            <p>For any inquiries or consultation session, feel free to reach out at:</p>
            <div className="contact-details">
                <p><strong>Email:</strong> <a href="mailto:info@codeandbeyond.in" target="_blank" className="social-link email" rel="noopener noreferrer">info@codeandbeyond.in</a></p>
                <p><strong>1:1 Connect:</strong> <a href="https://topmate.io/bhuvnesh_arya" target="_blank" className="social-link email" rel="noopener noreferrer">https://topmate.io/bhuvnesh_arya</a></p>
            </div>
        </div>
    </div>
  );
};

export default ContactMePage;
