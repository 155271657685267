import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';  // Import Axios for API calls
import '../styles/SignUpPage.css';

const SignUpPage = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    subscribe: false
  });

  const [formErrors, setFormErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [apiError, setApiError] = useState('');
  const navigate = useNavigate();

  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  // Validate form data
  const validateForm = () => {
    let errors = {};

    // First Name validation
    if (!formData.firstName.trim()) {
      errors.firstName = 'First Name is required';
    }

    // Last Name validation
    if (!formData.lastName.trim()) {
      errors.lastName = 'Last Name is required';
    }

    // Email validation
    if (!formData.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is invalid. Must contain "@"';
    }

    // Password validation
    if (!formData.password) {
      errors.password = 'Password is required';
    } else if (formData.password.length < 8) {
      errors.password = 'Password must be at least 8 characters long';
    }

    return errors;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        // Clear previous errors and messages
        setApiError('');
        setSuccessMessage('');

        // API call to signup
        await axios.post('https://codeandbeyond-user-service-bb33c5435e89.herokuapp.com/api/users/signup', {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          password: formData.password,
          subscribedToNewsletter: formData.subscribe
        }, {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true  // If your backend requires credentials (such as cookies)
        });

        // On success, display a message and redirect to another page
        setSuccessMessage('Account created successfully! Redirecting...');
        setTimeout(() => navigate('/lessons'), 2000);  // Redirect after 2 seconds

      } catch (error) {
        setApiError('Sign-up failed. ' + (error.response?.data?.message || 'Please try again later.'));
      }
    }
  };

  return (
    <div className="sign-up-container">
      <div className="sign-up-card">
        <h2>Create Your Account</h2>

        {/* API Error message */}
        {apiError && <p className="error">{apiError}</p>}

        {/* Success Message */}
        {successMessage && <p className="success">{successMessage}</p>}

        <form onSubmit={handleSubmit}>
          {/* First Name */}
          <div className="input-group">
            <label>First Name</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
            {formErrors.firstName && <p className="error">{formErrors.firstName}</p>}
          </div>

          {/* Last Name */}
          <div className="input-group">
            <label>Last Name</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
            {formErrors.lastName && <p className="error">{formErrors.lastName}</p>}
          </div>

          {/* Email */}
          <div className="input-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {formErrors.email && <p className="error">{formErrors.email}</p>}
          </div>

          {/* Password */}
          <div className="input-group">
            <label>Password</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            {formErrors.password && <p className="error">{formErrors.password}</p>}
          </div>

          {/* Subscribe to Newsletter */}
          <div className="input-group newsletter-checkbox">
            <input
              type="checkbox"
              name="subscribe"
              checked={formData.subscribe}
              onChange={handleChange}
            />
            <label>
                Subscribe to our newsletter to receive updates.
            </label>
          </div>

          {/* Submit Button */}
          <button type="submit" className="create-account-button">Create Account</button>
        </form>
      </div>
    </div>
  );
};

export default SignUpPage;
