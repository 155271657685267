const BlogData = [
    {
      title: 'IoT-Based Smart Cities - System Architecture',
      description: 'The rapid advancement of Internet of Things (IoT) technology has paved the way for the development of smart cities.',
      thumbnail: './images/iot-smart-city.png',
      link: 'https://www.linkedin.com/pulse/iot-based-smart-cities-system-architecture-bhuvnesh-arya/',
    },
    {
      title: 'The Essence of Domain-Driven Design (DDD)',
      description: 'Domain-Driven Design (DDD) is a software development approach that focuses on creating software that reflects and models the real-world business domains it is intended to support.',
      thumbnail: './images/domain-driven-design.jpg',
      link: 'https://www.linkedin.com/pulse/essence-domain-driven-design-ddd-bhuvnesh-arya/',
    },
    {
        title: 'Consistent Hashing: A Guide for Distributed Systems',
        description: 'In distributed systems, efficiently distributing data across multiple nodes is crucial for scalability and fault tolerance.',
        thumbnail: './images/consistent-hashing.png',
        link: 'https://www.linkedin.com/pulse/consistent-hashing-guide-distributed-systems-bhuvnesh-arya-b9nnc/',
    },
    {
        title: 'Event-Driven Architecture: Request/Reply Messaging',
        description: 'In the world of software architecture, the event-driven approach has gained substantial traction for its ability to decouple systems and enhance scalability.',
        thumbnail: './images/event-driven-arch.png',
        link: 'https://www.linkedin.com/pulse/event-driven-architecture-requestreply-messaging-bhuvnesh-arya-3uvxc/',
    },
    // Add more articles here
  ];
  
  export default BlogData;
  