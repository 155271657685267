import React from 'react';

const Lesson1 = () => {
  return (
    <div className="lesson-content">
      <h2>Lesson 1: Introduction</h2>
      <p>Coming soon... Stay updated</p>
    </div>
  );
};

export default Lesson1;
